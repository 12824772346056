import useNotifications from '@/composables/useNotifications'
import { getImprovement, updateImprovement } from '@/@core/queries/improvements'
import { updateActionPlan } from '@/@core/queries/action-plans'
import { useRouter } from '@core/utils/utils'
import { ref, computed, set } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import axios from '@axios'
import useCommon from "@/views/organization/useCommon"
import useCommonTodo from '@/views/apps/todo/useCommonTodo'
import store from '@/store'

export default function useCaseEdit() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { route, router } = useRouter()
  const { formatDate } = useCommon()
  const { uploadImprovementFile, sendEmailCase } = useCommonTodo()
  const caseData = ref({})
  const isSubmitting = ref(false)
  const updateActiveTab = ref(false)
  const userData = store.state?.userStore?.userData

  const show = () => {
    isSubmitting.value = true
    axios
      .post('/graphql', {
        query: getImprovement,
        variables: { query: { _id: route.value.params.id } },
      })
      .then(({ data }) => {
        if (data.errors) throw new Error(data.errors[0].message)

        const c = data?.data?.improvement || {}

        // Sort action plans by origin index
        c.actionPlans?.sort((a, b) => a.originIndex - b.originIndex)

        caseData.value = {
          ...c,
          assignee: c.assignee?._id,
          proposalResponsible: c.proposalResponsible?._id,
          approvalResponsible: c.approvalResponsible?._id,
          verificationResponsible: c.verificationResponsible?._id,
          associatedCases: c.associatedCases?.map(({ _id }) => _id),
          areas: c.areas?.map(({ _id }) => _id),
          actionPlans: c.actionPlans?.map(e => ({...e, executionResponsible: e.executionResponsible?._id})) || [],
          state: c.stage === 'end' ? 'completed' : 'upToDate'
        }

        // Set reactive properties
        caseData.value.actionPlans.forEach(e => {
          set(e, 'approvalNextStage', e.stage === 'analysis' || e.stage === 'corrective' || e.stage === 'verification' ? e.stage : '')
          set(e, 'correctiveNextStage', e.stage === 'analysis' || e.stage === 'verification' ? e.stage : '')
          set(e, 'verificationNextStage', e.stage === 'analysis' || e.stage === 'corrective' || e.stage === 'end' ? e.stage : '')
          set(e, 'evidenceFile', [])
        })

        // Check each action plan end date to see if the complaint is delayed
        if (caseData.value.state === 'upToDate') {
          for (const actionPlan of caseData.value.actionPlans) {
            if (actionPlan.endDate && new Date(actionPlan.endDate) < new Date()) {
              caseData.value.state = 'delayed'
              break
            }
          }
        }

        updateActiveTab.value = true
      })
      .catch(error => {
        console.log(error)
        showErrorMessage(i18n.t('message.case_fetch_error'))
      })
      .finally(() => {
        isSubmitting.value = false
      })
  }

  const update = (caseData) => {
    if (caseData.actionPlansToDelete?.length) deleteActionPlans(caseData.actionPlansToDelete)

    const payload = {
      note: caseData.note,
      assignee: { link: caseData.assignee },
      dueDate: formatDate(caseData.dueDate),
      description: caseData.description || '',
      associatedCases: caseData.associatedCases ? { link: caseData.associatedCases } : null,
      areas: caseData.areas ? { link: caseData.areas } : null,
      proposalResponsible: { link: caseData.assignee },
      approvalResponsible: caseData.approvalResponsible ? { link: caseData.approvalResponsible } : null,
      verificationResponsible: caseData.verificationResponsible ? { link: caseData.verificationResponsible } : null,
      proposalDueDate: caseData.proposalDueDate ? formatDate(caseData.proposalDueDate) : null,
      approvalDueDate: caseData.approvalDueDate ? formatDate(caseData.approvalDueDate) : null,
      verificationDueDate: caseData.verificationDueDate ? formatDate(caseData.verificationDueDate) : null,
      problemStatement: caseData.problemStatement || '',
      why1: caseData.why1 || '',
      why2: caseData.why2 || '',
      why3: caseData.why3 || '',
      why4: caseData.why4 || '',
      why5: caseData.why5 || '',
      actionPlans: { link: caseData.actionPlansIds || [] },
      approvalComment: caseData.approvalComment || '',
      verificationComment: caseData.verificationComment || '',
      isCompletedOverdue : caseData.isCompletedOverdue || null,
      lastModifiedBy: userData?.worker_id?.$oid ? { link: userData.worker_id.$oid } : null,
      rootCause: caseData.rootCause || 100,
    }

    // If case is in 'analysis' stage and there are action plans then set stage as 'approval'
    // If case is in 'approval' stage and all action plans are in stage 'corrective/verification' then set stage as 'corrective'
    // If case is in 'corrective' stage and all action plans are in stage 'verification' then set stage as 'verification'
    // If case is in 'verification' stage and all action plans are in stage 'end' then set stage as 'end'
    payload.stage = caseData.stage === 'analysis' && caseData.actionPlansIds?.length
      ? 'approval'
      : caseData.stage === 'approval' && caseData.actionPlans.some(e => e.stage === 'corrective' || e.approvalNextStage === 'corrective' || e.stage === 'verification' || e.approvalNextStage === 'verification')
        ? caseData.actionPlans.some(e => e.stage === 'verification' || e.approvalNextStage === 'verification')
          ? 'verification'
          : 'corrective'
        : caseData.stage === 'corrective' && caseData.actionPlans.some(e => e.stage === 'verification' || e.correctiveNextStage === 'verification')
          ? 'verification'
          : caseData.stage === 'verification' && caseData.actionPlans.every(e => e.verificationNextStage === 'end')
            ? 'end'
            : caseData.stage

    axios
      .post('/graphql', {
        query: updateImprovement,
        variables: { query: { _id: route.value.params.id }, data: payload },
      })
      .then(({ data }) => {
        if (data.errors) throw new Error(data.errors[0].message)
        showSuccessMessage(i18n.t('message.improvement_updated'))

        // Upload document to AWS and then update the case in MongoDB with the AWS document key
        if (caseData.documentFile?.length) {
          uploadImprovementFile(caseData.documentFile, "documents", route.value.params.id)
        }

        const originalStage = caseData.stage
        caseData.stage = payload.stage

        // Send email to assignee (registration responsible) with the details of the case updated
        sendEmailCase(caseData, "update", 'assignee', 'case')

        // If case passed to stage 'approval' send email to approval responsible with the details of the case updated
        if (caseData.approvalResponsible && originalStage !== 'approval' && payload.stage === 'approval') {
          sendEmailCase(caseData, "update", 'approvalResponsible', 'case')
        }

        // If case passed to stage 'verification' send email to verification responsible with the details of the case updated
        else if (caseData.verificationResponsible && originalStage !== 'verification' && payload.stage === 'verification') {
          sendEmailCase(caseData, "update", 'verificationResponsible', 'case')
        }
      })
      .catch(error => {
        console.log(error)
        showErrorMessage(i18n.t('message.improvement_update_error'))
      })
      .finally(() => {
        router.push({ name: 'organization-case-list' })
      })
  }

  const deleteActionPlans = (ids) => {
    ids.forEach(e => {
      axios
        .post('/graphql', {
          query: updateActionPlan,
          variables: { query: { _id: e }, data: { deleted: true } },
        })
        .then(({ data }) => {
          if (data.errors) throw new Error(data.errors[0].message)
        })
        .catch(error => {
          console.log(error)
        })
    })
  }

  return {
    update,
    show,
    caseData,
    isSubmitting,
    updateActiveTab,
  }
}
